<template>
  <div class="vx-row flex center">
    <div class="vx-col w-full mb-base">
      <vx-card :title="$t('Service')" :subtitle="$t('Servicesubtitle')" class="mb-base items-center sm:px-4 px-0">
        <!-- 新增服務項目 -->
        <div class="vx-row flex justify-content-between">
          <vs-col vs-type="flex" vs-justify="space-between">
            <!-- 新增服務項目 -->
            <div class="flex">
              <vs-input v-on:keyup.13="addWord" vs-justify="start" :placeholder="$t('AddService')"  @keyup.enter="word" v-model="word"/>
              <vs-button :size="windowWidth < 576 ? 'small' :''" class="sm:ml-6 ml-3 py-2 sm:px-6 px-3" color="primary" :disabled="word.length==0" @click="addWord">{{$t('AddBtn')}}</vs-button>
            </div>
            <!-- 匯入 button -->
            <vs-button :size="windowWidth < 576 ? 'small' :''" class="py-1 px-3 mr-3" color="warning" @click="DialogVisibleWord=true">
              <feather-icon style="vertical-align: middle;" icon="FilePlusIcon" />
              <b v-if="windowWidth >= 576" class="ml-1" style="font-size: 16px; vertical-align: middle;">{{$t('Import')}}</b>
            </vs-button>
          </vs-col>
        </div>
        <div class="vx-row flex center">
          <!-- 資料 -->
          <vs-list>
            <vs-list-item :class="windowWidth < 768 ? 'cd-list-item-sm w-full' : ''" :title="item.word" v-for="(item, index) in words" :key="index">
                <vs-col vs-type="flex" vs-justify="flex-end">
                  <feather-icon icon="EditIcon" class="m-1 cursor-pointer hover:text-primary" @click="showEditDialog(item)"></feather-icon>
                  <feather-icon icon="Trash2Icon" class="m-1 cursor-pointer hover:text-primary" @click="delWord(item)"></feather-icon>
                </vs-col>
              </vs-list-item>
            <vs-list-item v-show="words==''" :title="$t('nodata')"></vs-list-item>
          </vs-list>
          <!-- 分頁 -->
          <vs-col class="mb-4 mt-2" vs-type="flex" vs-justify="center">
            <vs-pagination :max="windowWidth<768 ? 5 : 7" :total="Math.ceil(serviceTotal/15)" v-model="page" @change="pageChange(page)"></vs-pagination>
          </vs-col>
        </div>  
      </vx-card>
      <!-- 編輯視窗 -->
      <vs-prompt
        :title="$t('Edit')"
        :accept-text="$t('Update')"
        :cancel-text="$t('Cancel')"
        @cancel="editword=''"
        @accept="acceptEdit"
        :active.sync="activeEdit">
        <div class="con-exemple-prompt">
            {{ $t('enterText') }}
          <vs-input placeholder="Code" v-model="editword"/>
        </div>
      </vs-prompt>
      <el-dialog
        :title="$t('settingSetword')"
        :visible.sync="DialogVisibleWord"
        :width="windowWidth > 768 ? '50%' :'80%'"
        center>
        <!--步驟-->
          <el-steps :active="importwordstep">
            <el-step :title="$t('downloadtemplate')" icon="el-icon-download">
            </el-step>
            <el-step :title="$t('uploadfile')" icon="el-icon-upload">
            </el-step>
            <el-step :title="$t('preview_data')">
            </el-step>
            <el-step :title="$t('Finish')">
            </el-step>
          </el-steps>
          <!--下載模板-->
          <div style="text-align:center; margin:20px 0;" v-show="this.importwordstep==1">
            <h1>
              <a href="https://upload.curdoctor.com.tw/excel_template/upload_service_example.xlsx" style="color:black; text-decoration:none;">
                {{$t('downloadtemplateexample')}}<el-button type="info" icon="el-icon-download" circle></el-button>
              </a>
            </h1>
          </div>
          <!--上傳文件-->
          <div style="text-align:center; margin:20px 0;" v-show="this.importwordstep==2">
            <el-alert :title="$t('onlyexcelfile')" type="warning" show-icon style="margin:20px 0;"></el-alert>
            <el-upload
              class="upload-demo"
              drag
              action=""
              :multiple="false"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="ChangeUpload"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text" v-html="$t('dragfile')"></div>
              <div class="el-upload__tip" slot="tip">
              </div>
            </el-upload>
            <!-- <input type="file" @change="previewFiles" /> -->
          </div>
          <!--預覽資料-->
          <div style="text-align:center; margin:20px 0;" v-show="this.importwordstep==3">
            <!-- {{previewworddata}} -->
            <el-table
              :data="previewworddata"
              height="250"
              border
              style="width: 100%">
              <el-table-column
                prop="type"
                :label="$t('serviceType')"
                >
                <template slot-scope="scope">
                  <div v-if="scope.row.type=='service'">
                    <div style="text-align:center; font-size:15px;">{{ scope.row.type | type }}</div>
                  </div>
                  <div v-else>
                      <div style="text-align:center; font-size:15px;">{{ scope.row.type }}</div>
                      <el-alert :title="$t('notFoundService')" type="warning" show-icon style="margin:20px 0;"></el-alert>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="word"
                :label="$t('serviceSetting')">
                <template slot-scope="scope">
                  <div  v-if="scope.row.word==''">
                    <div style="text-align:center; font-size:15px;">{{ scope.row.word }}</div>
                    <el-alert :title="$t('message.data_not_input')" type="warning" show-icon style="margin:20px 0;"></el-alert>
                  </div>
                  <div v-else>
                    <div style="text-align:center; font-size:15px;">{{ scope.row.word }}</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--完成-->
          <div style="text-align:center; margin:20px 0;" v-show="this.importwordstep==4">
            <h1>
              {{$t('message.upload_success')}}
            </h1>
          </div>
        <!--標籤-->
          <div style="text-align:center;">
          <vs-button v-show="this.importwordstep!=1 && this.importwordstep!=4" :size="windowWidth > 576 ? '' :'small'" class="m-1" color="primary" @click="previousstep">{{$t('prevstep')}}</vs-button>
          <vs-button v-show="this.importwordstep!=4 && this.importwordstep!=2 && this.importwordstep!=3" :size="windowWidth > 576 ? '' :'small'" class="m-1" color="primary" @click="nextstep">{{$t('nextstep')}}</vs-button>
          <vs-button v-show="this.importwordstep==3" :size="windowWidth > 576 ? '' :'small'" class="m-1" color="primary" @click="importword">{{$t('importmultiple')}}</vs-button>
          <vs-button v-show="this.importwordstep==4" :size="windowWidth > 576 ? '' :'small'" class="m-1" color="primary" @click="closeDialogVisibleWord">{{$t('view_details')}}</vs-button>
          </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import XLSX from 'xlsx'
export default {
  data() {
    return {
      close: '',
      editword: '',
      editid: 0,
      delid: 0,
      activeEdit: false,
      selecttype: 'service',
      word: '',
      servicePage: 1,
      serviceTotal: 0,
       //服務列表多筆匯入
      DialogVisibleWord:false,
      importwordstep:1,
      previewworddata:null,
      page: 1,
    }
  },
  mounted() {
    this.fetchWords()
    this.pageChange(1)
  },
  computed: {
    windowWidth() {
        var windowwidth = this.$store.state.windowWidth
        return windowwidth
    },
    words(){
      if(this.selecttype=='service'){
        return this.$store.getters.servicelist
      }
    }
  },
  methods: {
    // 刪除視窗
    delWord(item){
      var _self = this
      _self.delid = item.id
      _self.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: _self.$t('Confirm'),
        text: _self.$t('message.confirm_delete_record'),
        accept:_self.acceptDel
      })
    },
    // 開啟編輯視窗
    showEditDialog(item){
      this.editword = item.word
      this.editid = item.id
      this.activeEdit = true
    },
    // 更新服務列表
    acceptEdit(){
      var _self = this
      _self.$vs.loading()
      _self.$store.dispatch('editWord', {'id':_self.editid, 'word': _self.editword, 'type':_self.selecttype})
        .then((res) => {
          console.log('edit word  ', res)
          _self.activeEdit = false
          _self.fetchWords()
          _self.$vs.loading.close()
        })
    },
    // 刪除服務列表
    acceptDel(color){
      var _self = this
      _self.$vs.loading()
      _self.$store.dispatch('delWord', {'id':_self.delid, 'type':_self.selecttype})
        .then((res) => {
          // console.log('edit word  ', res)
          if(res.data.status=='OK'){
            _self.$vs.notify({
              color:'success',
              title:_self.$t('message.send_success'),
              text:_self.$t('message.delete_success'),
              position:'top-center'
            })
          }else{
            _self.$vs.notify({
              color:'danger',
              title:_self.$t('message.send_error'),
              text:_self.$t('message.delete_error'),
              position:'top-center'
            })
          }
          _self.fetchWords()
          _self.$vs.loading.close()
        })
    },
    // 新增服務列表
    addWord(){
      var _self = this
      if(_self.word!=''){
        _self.$vs.loading()
        let arrayPayload = [{'type':_self.selecttype, 'word': _self.word}]
        _self.$store.dispatch('addWord', arrayPayload)
          .then(() => {
            _self.word = ''
            _self.fetchWords()
            _self.$vs.loading.close()
        })
      }
    },
    // 取服務列表
    fetchWords() {
      var _self = this
      _self.$vs.loading()
      _self.$store.dispatch('fetchWords', _self.selecttype+'/'+_self.setwordPage)
        .then((res) => {
          _self.serviceTotal = res.data.total
          _self.$vs.loading.close()
        })
    },
     // 選取服務列表
    selectType() {
      this.fetchWords()
    },
    // 多筆匯入上一部按鈕
    previousstep(){
      this.importwordstep--
    },
    // 多筆匯入下一步按鈕
    nextstep() {
      this.importwordstep++
    },
      // 檔案上傳
     ChangeUpload(event){
      let _self = this;
      var testmsg=event.name.substring(event.name.lastIndexOf('.')+1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      if(!extension && !extension2) {
        _self.$vs.notify({
          color: 'danger',
          title: _self.$t('message.send_error'),
          text: _self.$t('message.upload_file_error'),
          position:'top-center'
        })
        return false;
      }
        var files = event.raw
        var reader = new FileReader();
        reader.onload = function(e) {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, {type: 'array'});
          let sheetName = workbook.SheetNames[0]
            /* DO SOMETHING WITH workbook HERE */
            let worksheet = workbook.Sheets[sheetName];
            _self.previewworddata = XLSX.utils.sheet_to_json(worksheet)
            _self.nextstep()
        };
        reader.readAsArrayBuffer(files)
     },
    // 多筆匯入
    importword(){
      var _self = this
      _self.$vs.loading()
      _self.$store.dispatch('addWord', _self.previewworddata)
      .then(() => {
        _self.fetchWords()
        _self.$vs.loading.close()
      })
      _self.nextstep()
    },
    // 關閉匯入視窗
    closeDialogVisibleWord(){
      this.DialogVisibleWord = false
      this.importwordstep = 1
    },
    // 換頁
    pageChange(page) {
      this.setwordPage = page
      this.fetchWords()
      window.scrollTo(0,0);
    },
  },
}
</script>
